export const polDomainRegex = /\.partnersonline\.com/g
export const localDomainRegex = 'localhost'

export const PROJECT_SERVICE_API_DOMAIN_URL = '/project_services'
export const SIGN_SERVICE_API_DOMAIN_URL = '/sign_services'
export const KIT_SERVICE_API_DOMAIN_URL = '/kit_services'
export const ADMIN_SERVICE_API_DOMAIN_URL = '/admin_services'
export const DASHBOARD_SERVICE_API_DOMAIN_URL = '/dashboard_services'
export const NOTIFICATION_SERVICE_API_DOMAIN_URL = '/notification_services'

export const HEADER_OBJECT = { headers: { 'Content-Type': 'application/json' } }
export const ARRAY_BUFFER_CONFIG: any = {
  headers: { 'Content-Type': 'application/json' },
  responseType: 'arraybuffer',
}
//INTERNAL AD GROUP
export const BLUEPRINT_ADMIN = 'APP-RUBIX-BLUEPRINT-ADMIN'
export const BLUEPRINT_EDITOR = 'APP-RUBIX-BLUEPRINT-EDITOR'
export const PROJECT_ADMIN = 'APP-RUBIX-PROJECT-ADMIN'
export const PROJECT_EDITOR = 'APP-RUBIX-PROJECT-EDITOR'
export const SIGN_ADMIN = 'APP-RUBIX-SIGN-ADMIN'
export const SIGN_EDITOR = 'APP-RUBIX-SIGN-EDITOR'
export const KIT_ADMIN = 'APP-RUBIX-KIT-ADMIN'
export const KIT_EDITOR = 'APP-RUBIX-KIT-EDITOR'
export const PRINT_PRODUCTION = 'APP-RUBIX-PRINTPRODUCTION'
export const SUPER_ADMIN = 'APP-RUBIX-SUPERADMIN'
export const TPS_PRINT_VENDOR = 'APP-RUBIX-TPS'

//EXTERNAL AD GROUP
export const EXTERNAL_AGENCY_SEPARATOR = 'APP-OAUTH2-ISMS-MKTPARTNER'
export const EXTERNAL_PRODUCT_VENDOR_CAT_MAN = 'APP-OAUTH2-ISMS-MERCHVENDOR'
export const EXTERNAL_PRINT_VENDOR = 'APP-OAUTH2-ISMS-PRINTVENDOR'
export const EXTERNAL_KIT_VENDOR = 'APP-OAUTH2-ISMS-KITVENDOR'
export const EXTERNAL_ASSETHUB_VENDOR = 'APP-OAUTH2-ASSETHUB'
export const EXTERNAL_SAP_VENDOR = 'SAP_CRM_USERS'

export enum UserType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export const PERMISSION_SETS = {
  superAdmins: [SUPER_ADMIN],
  internalUsersGroup: [
    SUPER_ADMIN,
    BLUEPRINT_ADMIN,
    BLUEPRINT_EDITOR,
    PROJECT_ADMIN,
    PROJECT_EDITOR,
    SIGN_ADMIN,
    SIGN_EDITOR,
    KIT_ADMIN,
    KIT_EDITOR,
    PRINT_PRODUCTION,
  ],
  externalUsersGroup: [
    SUPER_ADMIN,
    EXTERNAL_AGENCY_SEPARATOR,
    EXTERNAL_PRODUCT_VENDOR_CAT_MAN,
    EXTERNAL_PRINT_VENDOR,
    EXTERNAL_KIT_VENDOR,
    EXTERNAL_ASSETHUB_VENDOR,
  ],

  blueprintEdit: [SUPER_ADMIN, BLUEPRINT_ADMIN, BLUEPRINT_EDITOR],
  projectEdit: [SUPER_ADMIN, PROJECT_ADMIN, PROJECT_EDITOR],
  signEdit: [SUPER_ADMIN, SIGN_ADMIN, SIGN_EDITOR],
  printKitEdit: [
    SUPER_ADMIN,
    KIT_ADMIN,
    KIT_EDITOR,
    EXTERNAL_KIT_VENDOR,
    EXTERNAL_PRINT_VENDOR,
  ],
}

export const SEARCH_RESPONSE_SIZE = 100

export const DASHBOARD_MANAGERS_LOCAL_STORAGE_KEY =
  'semDashboardAddedProjectManagers'

export enum NOTES_BUTTON {
  BLUEPRINT = 'Blueprint',
  PROJECT = 'Project',
  SIGN = 'Sign',
}

export const PROJECT_TYPES_OPTIONS = [
  { value: 'REV', label: 'REV' },
  { value: 'SPLN', label: 'SPLN' },
  { value: 'TRN', label: 'TRN' },
]

export enum BATCH_EDIT_ACTION {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum MY_MILESTONE_HEALTH {
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue',
  GREEN = 'green',
  GREY = 'grey',
}

export enum ALERT {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum PROJECT_WORKFLOW_STATUS {
  PENDING = 'Pending',
  READY = 'Ready',
  SIGNS_INITIATED = 'Signing',
  SIGNS_FINALIZED = 'Signs Finalized',
  READY_TO_KIT = 'Signs Ready for Kitting',
  KITS_CREATED = 'Kits Created',
  KITS_RECREATE = 'Recreate Kits',
  KITS_FINALIZED = 'Kits Finalized',
  KITS_RECREATE_AFTER_FINALIZE = 'Recreate Kits (Finalization)',
  KITS_MODIFIED_AFTER_FINALIZED = 'Kits Modified (Finalization)',
  COMPLETE = 'Complete',
  ARCHIVE = 'Archive',
  CANCEL = 'Cancel',
  REMOVE = 'Remove',
}

export const PROJECT_PRE_SIGNS_FINALIZED = [
  PROJECT_WORKFLOW_STATUS.PENDING.toString(),
  PROJECT_WORKFLOW_STATUS.READY.toString(),
  PROJECT_WORKFLOW_STATUS.SIGNS_INITIATED.toString(),
]

export const PROJECT_POST_SIGNS_FINALIZED = [
  PROJECT_WORKFLOW_STATUS.SIGNS_FINALIZED.toString(),
  PROJECT_WORKFLOW_STATUS.READY_TO_KIT.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_CREATED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE.toString(),
]

export const PROJECT_READY_TO_KIT = [
  PROJECT_WORKFLOW_STATUS.READY_TO_KIT.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_CREATED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE_AFTER_FINALIZE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_MODIFIED_AFTER_FINALIZED.toString(),
]

export const ALLOW_EXPORT_PSF = [
  PROJECT_WORKFLOW_STATUS.READY_TO_KIT.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_CREATED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE_AFTER_FINALIZE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_MODIFIED_AFTER_FINALIZED.toString(),
  PROJECT_WORKFLOW_STATUS.COMPLETE.toString(),
  PROJECT_WORKFLOW_STATUS.CANCEL.toString(),
  PROJECT_WORKFLOW_STATUS.ARCHIVE.toString(),
]

export const PROJECT_KITS_CREATED = [
  PROJECT_WORKFLOW_STATUS.KITS_CREATED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE_AFTER_FINALIZE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_MODIFIED_AFTER_FINALIZED.toString(),
]

export const PROJECT_POST_KITS_FINALIZED = [
  PROJECT_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_RECREATE_AFTER_FINALIZE.toString(),
  PROJECT_WORKFLOW_STATUS.KITS_MODIFIED_AFTER_FINALIZED.toString(),
]

export const PROJECT_WORKFLOW_LOCKED = [
  PROJECT_WORKFLOW_STATUS.ARCHIVE.toString(),
  PROJECT_WORKFLOW_STATUS.CANCEL.toString(),
  PROJECT_WORKFLOW_STATUS.COMPLETE.toString(),
  PROJECT_WORKFLOW_STATUS.REMOVE.toString(),
]

export enum SIGN_WORKFLOW_STATUS {
  NONE = 'N/A',
  INITIATED = 'Sign Initiated',
  READY_TO_FINALIZE = 'Ready To Finalize',
  FINALIZED = 'Sign Finalized',
  POG_IN_PROGRESS = 'POG In-Progress',
  ISM_ON_POG = 'ISM On POG',
  READY_TO_KIT = 'Ready To Kit',
  KITS_CREATED = 'Kits Created',
  KITS_FINALIZED = 'Kits Finalized',
  COMPLETE = 'Complete',
  // Ess Sign status after finalization.
  SENT_TO_ESS = 'Sent to ESS',
  SENT_TO_TPS = 'Sent to TPS',
}

export const PRE_SIGN_FINALIZATION = [
  SIGN_WORKFLOW_STATUS.INITIATED.toString(),
  SIGN_WORKFLOW_STATUS.READY_TO_FINALIZE.toString(),
]

export const PRE_SIGN_FINALIZATION_INCLUDING_REMOVED = [
  SIGN_WORKFLOW_STATUS.INITIATED.toString(),
  SIGN_WORKFLOW_STATUS.READY_TO_FINALIZE.toString(),
  SIGN_WORKFLOW_STATUS.NONE.toString(),
]

export const ALL_SIGNS_CAN_BE_FINALIZED = [
  SIGN_WORKFLOW_STATUS.READY_TO_FINALIZE.toString(),
  SIGN_WORKFLOW_STATUS.NONE.toString(),
  SIGN_WORKFLOW_STATUS.FINALIZED.toString(),
  SIGN_WORKFLOW_STATUS.POG_IN_PROGRESS.toString(),
  SIGN_WORKFLOW_STATUS.ISM_ON_POG.toString(),
  SIGN_WORKFLOW_STATUS.READY_TO_KIT.toString(),
  SIGN_WORKFLOW_STATUS.KITS_CREATED.toString(),
  SIGN_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
  SIGN_WORKFLOW_STATUS.SENT_TO_ESS.toString(),
  SIGN_WORKFLOW_STATUS.SENT_TO_TPS.toString(),
]

export const POST_SIGN_FINALIZATION = [
  SIGN_WORKFLOW_STATUS.NONE.toString(),
  SIGN_WORKFLOW_STATUS.FINALIZED.toString(),
  SIGN_WORKFLOW_STATUS.POG_IN_PROGRESS.toString(),
  SIGN_WORKFLOW_STATUS.ISM_ON_POG.toString(),
  SIGN_WORKFLOW_STATUS.READY_TO_KIT.toString(),
  SIGN_WORKFLOW_STATUS.KITS_CREATED.toString(),
]

export const POST_KIT_FINALIZATION = [
  SIGN_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
  SIGN_WORKFLOW_STATUS.COMPLETE.toString(),
  SIGN_WORKFLOW_STATUS.SENT_TO_ESS.toString(),
  SIGN_WORKFLOW_STATUS.SENT_TO_TPS.toString(),
]

export const SIGN_DISTRIBUTION_FIELDS = [
  'kitting_vendor',
  'kit_by_itself',
  'kit_groups',
  'back_stock_quantity',
  'max_quantity_per_kit',
  'location_filter_info',
  'msc_selection',
  'exclude_msc',
  'include_msc',
  'select_stores',
  'quantity_per_store',
  'selected_msc',
  'include_new_stores',
]

export const SIGN_PRICING_FIELDS = [
  'estimated_unit_price',
  'estimated_quantity',
  'final_unit_price',
  'po_number',
]

export const SIGN_UNIT_PRICE_FIELDS = [
  'estimated_unit_price',
  'final_unit_price',
]

export const SIGN_PRINTING_FIELDS_OLD = [
  'planned_sides',
  'number_of_colors',
  'substrate1',
  'substrate2',
  'finishing',
  'coating',
  'die_cut',
  'pre_assembled',
  'file_approved',
]

export const SIGN_PRINTING_FIELDS = [
  'planned_sides',
  'number_of_colors',
  'substrate1',
  'substrate2',
  'finishing',
  'coating',
  'die_cut',
  'sides_print',
  'pre_assembled',
  'file_approved',
]

export const CONTRACT_FIELDS = [
  'finished_width',
  'finished_height',
  'finished_depth',
  'flat_width',
  'flat_height',
  'flat_depth',
  'planned_sides',
  'substrate1',
  'substrate2',
  'finishing',
  'die_cut',
  'dieline_name',
]

export const POG_FIELDS = [
  'pog_categories',
  'tie_to_product_dpci',
  'tie_to_hardware_dpci',
  'placement_on_shelf',
  'quantity_per_placement',
  'pog_unit_of_measure',
  'pog_exclusions',
]

export const REASON_CODE_REQUIRED_STATUSES = [
  'Signs Finalized',
  'Signs Ready for Kitting',
  'Kits Created',
  'Recreate Kits',
  'Kits Finalized',
  'Recreate Kits (Finalization)',
  'Complete',
]

export enum SIGN_LIBRARY_VIEW {
  STANDARD = 'Standard',
  PRINT_INFO = 'Printing Info',
  IMPORT = 'Import',
}

export enum BLUEPRINT_PROJECT_LIST_VIEW {
  ACTIVE = 'Active Projects',
  ARCHIVED = 'Archived/Canceled Projects',
  ALL = 'All SAP Projects (Active & Archived)',
}

export enum MESSAGING_INFO_SIGN_SIZE {
  FIVE_BY_THREE = 'FIVE_BY_THREE',
  SIX_BY_THREE = 'SIX_BY_THREE',
  EIGHT_BY_TWO = 'EIGHT_BY_TWO',
  SEVEN_BY_ELEVEN = 'SEVEN_BY_ELEVEN',
  ELEVEN_BY_ELEVEN = 'ELEVEN_BY_ELEVEN',
  OTHER = 'OTHER',
}

export enum NRI_SIGN_CREATOR {
  NONE = 'None',
  TDTP = 'Target Designed/Target Produced',
  TDVP = 'Target Designed/Vendor Produced',
  VDTP = 'Vendor Designed/Target Produced',
  VDVP = 'Vendor Designed/Vendor Produced',
}

export enum NRI_SIGN_ITEM_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  IN_DEV = 'In Development',
  RESTRICTED = 'Restricted',
  CANCELLED = 'Cancelled',
  REQUESTED = 'Requested',
  OBSOLETE = 'Obsolete',
}

export enum NRI_SIGN_LANGUAGE {
  ENG = 'English',
  BI = 'Bilingual',
}

export enum NRI_UNIT_OF_MEASURE {
  EA = 'EA',
  ST = 'ST',
  KT = 'KT',
}

export enum PROJECT_REASON_CODE {
  MISSING_POG = 'Missing from POG',
  MISSING_INSTRUCTION = 'Missing Instruction Sheet',
  LATE_MERCH_REQ = 'Late Merchant Request',
  LATE_PROTOTYPING = 'Late Prototyping',
  LATE_AGENCY_REQ = 'Late Agency Request',
  LATE_PHOTO = 'Late Photography',
  LATE_SIGN_STD_INPUT = 'Late Sign Standard Input Needed',
  LEGAL_REQ = 'Legal/Translation Request',
  MERCH_CHANGE = 'Merch. Change',
  BUDGET = 'Budget Decision',
  POG_BUILD = 'POG Build/Proof Discovery',
  FUTURE_SET_DATE = 'Move to Future Set Date',
  VENDOR_UPDATE = 'Vendor Update',
}

export enum SIGN_STATUS {
  NEW = 'New',
  CARRY_FORWARD = 'Carry Forward',
  REISSUE = 'Reissue',
  REMOVE = 'Remove',
  NA = 'N/A',
}

export const TOASTER_DEFAULTS = {
  autoHideDuration: 15000,
  pauseOnHover: true,
}
