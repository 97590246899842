import { get } from 'lodash'
import { KitGroupQuantity } from './DistributionResponse.model'

const filterDeptGroups = (groups: []) => {
  const filteredGroups = groups.filter(
    (group: any) => group.department_id !== '',
  )
  if (filteredGroups.length > 0) {
    return groups
  } else {
    return
  }
}

export default class DistributionRequest {
  back_stock_quantity?: number | undefined
  kit_by_itself?: boolean
  kit_groups?: string[]
  kit_group_quantities?: KitGroupQuantity[]
  kitting_vendor?: string
  kitting_vendor_id?: string
  location_filter_info: LocationFilterRequest
  max_quantity_per_kit?: number
  nrsc_quantity?: number
  quantity_per_store?: number
  total_quantity?: number
  include_new_stores: boolean
  updated_by: string

  constructor(props: any = {}) {
    this.back_stock_quantity =
      props.back_stock_quantity === ''
        ? undefined
        : parseInt(props.back_stock_quantity)
    this.kit_by_itself = props.kit_by_itself
    this.kit_groups = props.kit_groups
    this.kit_group_quantities = get(props, 'kit_group_quantities', []).map(
      (kitGroup: any) => new KitGroupQuantity(kitGroup),
    )
    this.kitting_vendor = props.kitting_vendor
    this.kitting_vendor_id = props.kitting_vendor_id
    this.location_filter_info = new LocationFilterRequest(
      props.location_filter_info || {},
    )
    this.max_quantity_per_kit = props.max_quantity_per_kit
    this.nrsc_quantity = props.nrsc_quantity
    this.quantity_per_store = props.quantity_per_store
    this.total_quantity =
      props.back_stock_quantity !== undefined
        ? parseInt(props.back_stock_quantity) + props.nrsc_quantity
        : props.nrsc_quantity
    this.include_new_stores = props.include_new_stores === true
    this.updated_by = props.updated_by || ''
  }
}

export class LocationFilterRequest {
  include_location_info: LocationInfoRequest
  exclude_location_info: LocationInfoRequest

  constructor(props: any = {}) {
    this.include_location_info = new LocationInfoRequest(
      props.include_location_info || {},
    )
    this.exclude_location_info = new LocationInfoRequest(
      props.exclude_location_info || {},
    )
  }
}

export class LocationInfoRequest {
  all_locations?: boolean
  department_location_groups?: DepartmentLocationGroup[]
  locations?: string[]

  constructor(props: any = {}) {
    this.all_locations = props.all_locations
    this.department_location_groups = filterDeptGroups(
      props.department_location_groups,
    )?.map((group: any) => new DepartmentLocationGroup(group))
    this.locations = props.locations.length > 0 ? props.locations : undefined
  }
}

export class DepartmentLocationGroup {
  department_id?: string
  group_id?: string
  sub_group_ids?: string[]
  quantity?: number

  constructor(props: any = {}) {
    this.department_id = props.department_id
    this.group_id = props.group_id !== '' ? props.group_id : undefined
    this.sub_group_ids =
      props.sub_group_ids.length > 0 ? props.sub_group_ids : undefined
    this.quantity = props.quantity
  }
}
