import { get } from 'lodash'
import {
  MESSAGING_INFO_SIGN_SIZE,
  NRI_SIGN_CREATOR,
  NRI_SIGN_ITEM_STATUS,
  NRI_SIGN_LANGUAGE,
  NRI_UNIT_OF_MEASURE,
} from 'components/App/constants/appConstants'
import { SignTemplateInfo } from '../signs/SignResponse.model'

export default class NonRetailItemInfo {
  brand?: string
  created_by: string
  created_date: string
  department?: Department
  dpci?: string
  end_date?: string
  id?: string
  is_migrated: boolean // default: false
  item_department_code?: string
  item_dimensions?: ItemDimensions
  messaging_info?: MessagingInfo
  nominal_dimensions?: NominalDimensions
  original_project_id?: string
  original_sap_project_id?: string
  photography_needed?: boolean
  printing_info?: SignPrintingInfo
  product_vendor: string
  reinstated: boolean // default: false
  send_to_back_stock: boolean // default: true
  separator: string
  set_date?: string
  set_quantity?: number // default: 'N/A' if unit_of_measure is 'EA', 1 if its 'ST' (UI only)
  sign_creator?: NRI_SIGN_CREATOR
  sign_description?: string
  sign_item_status?: NRI_SIGN_ITEM_STATUS
  sign_language?: NRI_SIGN_LANGUAGE
  sign_type?: string
  sign_template_info?: SignTemplateInfo
  sign_size?: string
  sign_definition?: string
  storage_urls?: string[]
  tcin?: string
  title?: string
  unit_of_measure?: NRI_UNIT_OF_MEASURE
  upc?: string
  updated_by: string // default: 'System'
  updated_date: string
  max_order_quantity?: number
  low_water_mark?: number
  restricted: boolean
  restriction_description?: string

  constructor(props: any = {}) {
    this.brand = props.brand
    this.created_by = props.created_by || 'System'
    this.created_date = props.created_date || ''
    this.department = props.department
      ? new Department(props.department)
      : undefined
    this.dpci = props.dpci
    this.end_date = props.end_date
    this.id = props.id
    this.is_migrated = props.is_migrated || false
    this.item_department_code = props.item_department_code
    this.item_dimensions = props.item_dimensions
      ? new ItemDimensions(props.item_dimensions)
      : undefined
    this.messaging_info = props.messaging_info
      ? new MessagingInfo(props.messaging_info)
      : undefined
    this.nominal_dimensions = props.nominal_dimensions
      ? new NominalDimensions(props.nominal_dimensions)
      : undefined
    this.original_project_id = props.original_project_id
    this.original_sap_project_id = props.original_sap_project_id
    this.photography_needed = props.photography_needed
    this.printing_info = props.printing_info
      ? new SignPrintingInfo(props.printing_info)
      : undefined
    this.product_vendor = props.product_vendor || ''
    this.reinstated = props.reinstated || false
    this.send_to_back_stock = props.send_to_back_stock || true
    this.separator = props.separator || ''
    this.set_date = props.set_date
    this.set_quantity = props.set_quantity
    this.sign_creator = props.sign_creator
    this.sign_description = props.sign_description
    this.sign_item_status = props.sign_item_status
    this.sign_language = props.sign_language
    this.sign_type = props.sign_type
    this.sign_template_info = props.sign_template_info
    this.sign_size = props.sign_size
    this.sign_definition = props.sign_definition
    this.storage_urls = props.storage_urls
    this.tcin = props.tcin
    this.title = props.title
    this.unit_of_measure = props.unit_of_measure
    this.upc = props.upc
    this.updated_by = props.updated_by || 'System'
    this.updated_date = props.updated_date || ''
    this.max_order_quantity = props.max_order_quantity
    this.low_water_mark = props.low_water_mark
    this.restricted = props.restricted
    this.restriction_description = props.restriction_description
  }
}

export class Department {
  department_display_name?: string
  department_id: number
  department_name?: string
  department_type_code?: string
  division_display_name?: string
  division_id: number
  division_name: string
  group_display_name?: string
  group_id?: number
  group_name?: string
  merch_legal?: string
  sub_group_id?: number
  sub_group_name?: string

  constructor(props: any = {}) {
    this.department_display_name =
      props.department_id === undefined
        ? '- (0)'
        : props.department_display_name
    this.department_id = props.department_id || 0
    this.department_name = props.department_name
    this.department_type_code = props.department_type_code
    this.division_display_name = props.division_display_name
    this.division_id = props.division_id || 0
    this.division_name = props.division_name || ''
    this.group_display_name = props.group_display_name
    this.group_id = props.group_id
    this.group_name = props.group_name
    this.merch_legal = props.merch_legal
    this.sub_group_id = props.sub_group_id
    this.sub_group_name = props.sub_group_name
  }
}

export class ItemDimensions {
  depth?: string
  dimension_unit_of_measure?: string
  height?: string
  weight?: string
  weight_unit_of_measure?: string
  width?: string

  constructor(props: any = {}) {
    this.depth = props.depth
    this.dimension_unit_of_measure = props.dimension_unit_of_measure
    this.height = props.height
    this.weight = props.weight
    this.weight_unit_of_measure = props.weight_unit_of_measure
    this.width = props.width
  }
}

export class NominalDimensions {
  depth?: string
  dimension_unit_of_measure?: string
  height?: string
  width?: string

  constructor(props: any = {}) {
    this.depth = props.depth
    this.dimension_unit_of_measure = props.dimension_unit_of_measure
    this.height = props.height
    this.width = props.width
  }
}

export class MessagingInfo {
  disclaimer?: string
  headline?: string
  item_info?: MessagingItemInfo[]
  mpp?: boolean
  sign_size: MESSAGING_INFO_SIGN_SIZE
  status: string
  topper_brand?: string

  constructor(props: any = {}) {
    this.disclaimer = props.disclaimer
    this.headline = props.headline
    this.item_info = props.item_info
      ? get(props, 'item_info', [{}]).map(
          (item: any) => new MessagingItemInfo(item),
        )
      : undefined
    this.mpp = props.mpp || false
    this.sign_size = props.sign_size || 'FIVE_BY_THREE'
    this.status = props.status || 'In Progress'
    this.topper_brand = props.topper_brand
  }
}

export class MessagingItemInfo {
  attributes?: string[]
  manual_price_override: boolean
  messaging_type?: string
  messaging_value?: MessageValue
  product_description?: string
  product_dpci?: string
  product_tcin?: string

  constructor(props: any = {}) {
    this.attributes = props.attributes
    this.manual_price_override = props.manual_price_override || false
    this.messaging_type = props.messaging_type
    this.messaging_value = props.messaging_value
      ? new MessageValue(props.messaging_value)
      : undefined
    this.product_description = props.product_description
    this.product_dpci = props.product_dpci
    this.product_tcin = props.product_tcin
  }
}

export class MessageValue {
  highest_price?: number
  lowest_price?: number
  price?: number
  quantity?: number

  constructor(props: any = {}) {
    this.highest_price = props.highest_price
    this.lowest_price = props.lowest_price
    this.price = props.price
    this.quantity = props.quantity
  }
}

export class SignPrintingInfo {
  coating?: string
  finishing?: string
  number_of_colors?: number
  sides_print: boolean
  planned_sides?: number
  pre_assembled: boolean
  print_vendor?: string
  print_vendor_id?: string
  substrate1?: string
  substrate2?: string
  dieline_name: string

  constructor(props: any = {}) {
    this.coating = props.coating
    this.finishing = props.finishing
    this.number_of_colors = props.number_of_colors
    this.sides_print = props.sides_print || false
    this.planned_sides = props.planned_sides
    this.pre_assembled = props.pre_assembled || false
    this.print_vendor = props.print_vendor
    this.print_vendor_id = props.print_vendor_id
    this.substrate1 = props.substrate1
    this.substrate2 = props.substrate2
    this.dieline_name = props.dieline_name
  }
}
