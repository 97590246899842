import { Column } from '../../../models/agGrid/AgGridColumn.model'
import { AgGridSignListView } from '../../../models/agGrid/AgGridSignListView.model'
import { SIGN_LIBRARY_VIEW } from '../../App/constants/appConstants'

export const SIGN_LIBRARY_STANDARD_VIEW_OLD = new AgGridSignListView({
  viewKey: 'standard',
  label: SIGN_LIBRARY_VIEW.STANDARD,
  columns: [
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI#',
      width: 192,
      type: ['fixed', 'dpci'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_description',
      headerName: 'Sign Name',
      width: 320,
      type: ['fixed', 'textEditor', 'textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_type',
      headerName: 'Sign Type',
      width: 210,
      type: ['fixed', 'signType', 'selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 225,
      type: ['signItemStatus'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_template_info.template_name',
      headerName: 'Standard Sign',
      width: 210,
      type: ['signTemplate', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.set_date',
      headerName: 'Original Set Date',
      width: 210,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 174,
      type: ['date', 'dateEditor'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.department',
      headerName: 'Department',
      width: 220,
      type: ['department', 'departmentEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 214,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
      width: 175,
      type: ['textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.product_vendor',
      headerName: 'Product Vendor',
      width: 228,
      type: ['productVendor', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.separator',
      headerName: 'Separator',
      width: 244,
      type: ['separator', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Creation Date',
      width: 210,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      headerName: 'SAP Project ID#',
      field: 'project_sign_info_list.sap_project_id',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'SAP Project Name',
      field: 'project_sign_info_list.project_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Blueprint Name',
      field: 'project_sign_info_list.blueprint_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Sign Status',
      field: 'project_sign_info_list.sign_status',
      type: ['signStatus', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Pyramid',
      field: 'non_retail_item_info.department.group_display_name',
      type: ['pyramid'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Division',
      field: 'non_retail_item_info.department.division_display_name',
      type: ['division'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Project Type',
      field: 'project_sign_info_list.project_type',
      type: ['projectType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Campaign Type',
      field: 'project_sign_info_list.campaign.name',
      type: ['campaignType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Print Vendor',
      field: 'non_retail_item_info.printing_info.print_vendor',
      type: ['printVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Kitting Vendor',
      field: 'project_sign_info_list.kitting_vendor',
      type: ['kittingVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Created Date',
      width: 182,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.reinstated',
      headerName: 'Reinstated',
      type: ['hiddenColumn'],
    }),
    new Column({
      field: 'project_sign_info_list.set_date',
      headerName: 'Project Set Date',
      type: ['detailDate', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
  ],
})

export const SIGN_LIBRARY_STANDARD_VIEW = new AgGridSignListView({
  viewKey: 'standard',
  label: SIGN_LIBRARY_VIEW.STANDARD,
  columns: [
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI#',
      width: 192,
      type: ['fixed', 'dpci'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_description',
      headerName: 'Sign Name',
      width: 320,
      type: ['fixed', 'textEditor', 'textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_type',
      headerName: 'Sign Type',
      width: 210,
      type: ['fixed', 'signType', 'selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 225,
      type: ['signItemStatus'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['signSize', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_definition',
      headerName: 'Sign Definition',
      width: 250,
      type: ['signDefinition', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.set_date',
      headerName: 'Original Set Date',
      width: 210,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 174,
      type: ['date', 'dateEditor'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.department',
      headerName: 'Department',
      width: 220,
      type: ['department', 'departmentEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 214,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
      width: 175,
      type: ['textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.product_vendor',
      headerName: 'Product Vendor',
      width: 228,
      type: ['productVendor', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.separator',
      headerName: 'Separator',
      width: 244,
      type: ['separator', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Creation Date',
      width: 210,
      type: ['date'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      headerName: 'SAP Project ID#',
      field: 'project_sign_info_list.sap_project_id',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'SAP Project Name',
      field: 'project_sign_info_list.project_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Blueprint Name',
      field: 'project_sign_info_list.blueprint_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Sign Status',
      field: 'project_sign_info_list.sign_status',
      type: ['signStatus', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Pyramid',
      field: 'non_retail_item_info.department.group_display_name',
      type: ['pyramid'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Division',
      field: 'non_retail_item_info.department.division_display_name',
      type: ['division'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Project Type',
      field: 'project_sign_info_list.project_type',
      type: ['projectType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Campaign Type',
      field: 'project_sign_info_list.campaign.name',
      type: ['campaignType'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Print Vendor',
      field: 'non_retail_item_info.printing_info.print_vendor',
      type: ['printVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Kitting Vendor',
      field: 'project_sign_info_list.kitting_vendor',
      type: ['kittingVendor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Created Date',
      width: 182,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.reinstated',
      headerName: 'Reinstated',
      type: ['hiddenColumn'],
    }),
    new Column({
      field: 'project_sign_info_list.set_date',
      headerName: 'Project Set Date',
      type: ['detailDate', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.max_order_quantity',
      headerName: 'Max Order Qty',
      width: 214,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.low_water_mark',
      headerName: 'Low Water Mark',
      width: 214,
      type: ['numberEditor', 'numberFilter'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.restricted',
      headerName: 'Restricted',
      width: 158,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.restriction_description',
      headerName: 'Restriction Description',
      width: 320,
      type: ['textEditor', 'textFilter'],
    }),
  ],
})

export const SIGN_LIBRARY_PRINT_VIEW_OLD = new AgGridSignListView({
  viewKey: 'printInfo',
  label: SIGN_LIBRARY_VIEW.PRINT_INFO,
  columns: [
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI#',
      width: 192,
      type: ['fixed', 'dpci'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_description',
      headerName: 'Sign Name',
      width: 320,
      type: ['fixed', 'textEditor', 'textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_type',
      headerName: 'Sign Type',
      width: 210,
      type: ['fixed', 'signType', 'selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 225,
      type: ['signItemStatus'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_template_info.template_name',
      headerName: 'Standard Sign',
      width: 210,
      type: ['signTemplate', 'autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Unit of Measure',
      field: 'non_retail_item_info.unit_of_measure',
      width: 204,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Set Quantity',
      field: 'non_retail_item_info.set_quantity',
      width: 142,
      type: ['setQuantity', 'setQtyEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 214,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.substrate2',
      headerName: 'Substrate 2',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.finishing',
      headerName: 'Finishing',
      width: 177,
      type: ['textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.coating',
      headerName: 'Coating',
      width: 169,
      type: ['textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.die_cut',
      headerName: 'Die Cut',
      type: ['textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.pre_assembled',
      headerName: 'Pre-Assembled',
      width: 158,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'SAP Project ID#',
      field: 'project_sign_info_list.sap_project_id',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'SAP Project Name',
      field: 'project_sign_info_list.project_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Blueprint Name',
      field: 'project_sign_info_list.blueprint_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_template_info.template_name',
      headerName: 'Standard Sign',
      width: 210,
      type: ['signTemplate', 'autocompleteEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Sign Status',
      field: 'project_sign_info_list.sign_status',
      type: ['signStatus', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
      width: 175,
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      field: 'non_retail_item_info.product_vendor',
      headerName: 'Product Vendor',
      width: 228,
      type: ['productVendor', 'autocompleteEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.separator',
      headerName: 'Separator',
      width: 244,
      type: ['separator', 'autocompleteEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.set_date',
      headerName: 'Original Set Date',
      width: 210,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 174,
      type: ['date', 'dateEditor', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Creation Date',
      width: 210,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      headerName: 'Pyramid',
      field: 'non_retail_item_info.department.group_display_name',
      type: ['pyramid', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Division',
      field: 'non_retail_item_info.department.division_display_name',
      type: ['division', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.department',
      headerName: 'Department',
      width: 220,
      type: ['department', 'departmentEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Project Type',
      field: 'project_sign_info_list.project_type',
      type: ['projectType', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Campaign Type',
      field: 'project_sign_info_list.campaign.name',
      type: ['campaignType', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Print Vendor',
      field: 'non_retail_item_info.printing_info.print_vendor',
      type: ['printVendor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Kitting Vendor',
      field: 'project_sign_info_list.kitting_vendor',
      type: ['kittingVendor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'project_sign_info_list.set_date',
      headerName: 'Project Set Date',
      type: ['detailDate', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
  ],
})

export const SIGN_LIBRARY_PRINT_VIEW = new AgGridSignListView({
  viewKey: 'printInfo',
  label: SIGN_LIBRARY_VIEW.PRINT_INFO,
  columns: [
    new Column({
      type: ['signMenu', 'fixed'],
    }),
    new Column({
      field: 'non_retail_item_info.dpci',
      headerName: 'DPCI#',
      width: 192,
      type: ['fixed', 'dpci'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_description',
      headerName: 'Sign Name',
      width: 320,
      type: ['fixed', 'textEditor', 'textFilter'],
    }),
    new Column({
      field: 'non_retail_item_info.sign_type',
      headerName: 'Sign Type',
      width: 210,
      type: ['fixed', 'signType', 'selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_item_status',
      headerName: 'Sign Item Status',
      width: 225,
      type: ['signItemStatus'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_size',
      headerName: 'Sign Size',
      width: 170,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.sign_definition',
      headerName: 'Sign Definition',
      width: 250,
      type: ['autocompleteEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Unit of Measure',
      field: 'non_retail_item_info.unit_of_measure',
      width: 204,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Set Quantity',
      field: 'non_retail_item_info.set_quantity',
      width: 142,
      type: ['setQuantity', 'setQtyEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.width',
      headerName: 'Flat Width',
      width: 214,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.height',
      headerName: 'Flat Height',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.nominal_dimensions.depth',
      headerName: 'Flat Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.width',
      headerName: 'Finished Width',
      width: 214,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.height',
      headerName: 'Finished Height',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.item_dimensions.depth',
      headerName: 'Finished Depth',
      width: 220,
      type: ['numberEditor', 'numberFilter', 'requiredField'],
      filter: 'agNumberColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.planned_sides',
      headerName: 'Planned Sides',
      width: 212,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.number_of_colors',
      headerName: '# of Colors',
      width: 197,
      type: ['selectEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.substrate1',
      headerName: 'Substrate 1',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.substrate2',
      headerName: 'Substrate 2',
      width: 196,
      type: ['autocompletesubstrateEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.finishing',
      headerName: 'Finishing',
      width: 177,
      type: ['textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.coating',
      headerName: 'Coating',
      width: 169,
      type: ['textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.dieline_name',
      headerName: 'Dieline Name',
      type: ['textEditor'],
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.sides_print',
      headerName: 'Sides Print',
      width: 120,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.printing_info.pre_assembled',
      headerName: 'Pre-Assembled',
      width: 158,
      type: ['toggle', 'toggleEditor'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'SAP Project ID#',
      field: 'project_sign_info_list.sap_project_id',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'SAP Project Name',
      field: 'project_sign_info_list.project_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Blueprint Name',
      field: 'project_sign_info_list.blueprint_name',
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      headerName: 'Sign Status',
      field: 'project_sign_info_list.sign_status',
      type: ['signStatus', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.upc',
      headerName: 'Sign UPC',
      width: 175,
      type: ['textFilter', 'hiddenColumn'],
    }),
    new Column({
      field: 'non_retail_item_info.product_vendor',
      headerName: 'Product Vendor',
      width: 228,
      type: ['productVendor', 'autocompleteEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.separator',
      headerName: 'Separator',
      width: 244,
      type: ['separator', 'autocompleteEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.set_date',
      headerName: 'Original Set Date',
      width: 210,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.end_date',
      headerName: 'End Date',
      width: 174,
      type: ['date', 'dateEditor', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.created_date',
      headerName: 'Creation Date',
      width: 210,
      type: ['date', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
    new Column({
      headerName: 'Pyramid',
      field: 'non_retail_item_info.department.group_display_name',
      type: ['pyramid', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Division',
      field: 'non_retail_item_info.department.division_display_name',
      type: ['division', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'non_retail_item_info.department',
      headerName: 'Department',
      width: 220,
      type: ['department', 'departmentEditor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Project Type',
      field: 'project_sign_info_list.project_type',
      type: ['projectType', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Campaign Type',
      field: 'project_sign_info_list.campaign.name',
      type: ['campaignType', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Print Vendor',
      field: 'non_retail_item_info.printing_info.print_vendor',
      type: ['printVendor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      headerName: 'Kitting Vendor',
      field: 'project_sign_info_list.kitting_vendor',
      type: ['kittingVendor', 'hiddenColumn'],
      filter: 'agSetColumnFilter',
    }),
    new Column({
      field: 'project_sign_info_list.set_date',
      headerName: 'Project Set Date',
      type: ['detailDate', 'hiddenColumn'],
      filter: 'agDateColumnFilter',
    }),
  ],
})

export const DETAIL_PROJECT_LIST = [
  new Column({
    headerName: 'SAP Project ID#',
    field: 'sap_project_id',
    width: 230,
    type: ['fixed'],
    filter: 'agSetColumnFilter',
  }),
  {
    headerName: 'SAP Project Name',
    field: 'project_name',
    width: 314,
    cellRenderer: 'ProjectName',
    type: ['textFilter', 'fixed'],
  },
  {
    headerName: 'Sign Status',
    field: 'sign_status',
    width: 205,
    type: ['signStatus', 'fixed'],
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Blueprint Name',
    field: 'blueprint_name',
    width: 314,
    cellRenderer: 'BlueprintName',
    type: ['textFilter'],
  },
  {
    headerName: 'Project Set Date',
    field: 'set_date',
    width: 231,
    type: ['detailDate'],
    filter: 'agDateColumnFilter',
  },
  {
    headerName: 'Campaign ID',
    field: 'campaign_id',
    width: 200,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Campaign Type',
    field: 'campaign.name',
    width: 278,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Finalized Level',
    field: 'workflow.status',
    width: 218,
    cellRenderer: 'FinalizedCell',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Project Type',
    field: 'project_type',
    width: 185,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'On POG Sign',
    field: 'on_pog_sign',
    width: 136,
    type: ['toggle'],
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Print Vendor',
    field: 'print_vendor',
    width: 210,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Kitting Vendor',
    field: 'kitting_vendor',
    width: 210,
    filter: 'agSetColumnFilter',
  },
]
