import SignResponse from '../../../models/signs/SignResponse.model'
import { UserPermissions } from '../../../models/app/UserPermissions.model'
import {
  ALL_SIGNS_CAN_BE_FINALIZED,
  CONTRACT_FIELDS,
  POG_FIELDS,
  POST_KIT_FINALIZATION,
  POST_SIGN_FINALIZATION,
  PRE_SIGN_FINALIZATION,
  PROJECT_KITS_CREATED,
  PROJECT_POST_KITS_FINALIZED,
  PROJECT_POST_SIGNS_FINALIZED,
  PROJECT_PRE_SIGNS_FINALIZED,
  PROJECT_WORKFLOW_LOCKED,
  REASON_CODE_REQUIRED_STATUSES,
  SIGN_DISTRIBUTION_FIELDS,
  SIGN_PRICING_FIELDS,
  SIGN_UNIT_PRICE_FIELDS,
  SIGN_PRINTING_FIELDS,
  SIGN_PRINTING_FIELDS_OLD,
  SIGN_WORKFLOW_STATUS,
  UserType,
} from '../constants/appConstants'
import { SIGN_STATUS } from '../../SignDetails/constants/signDetailsConstants'
import { isCategoryManager } from './isCategoryManager'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { isCreativeAgent } from '../components/isCreativeAgent'
import moment from 'moment'
import { UserInformation } from '../../../models/app/UserInfo.model'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import {
  userIsCategoryManager,
  userIsCreativeAgent,
} from './userPermissionsHelper'
import { SIGN_SIZE_FACETS } from '../../SignDetails/constants/signMessagingFacets'
import { SIGN_SIZES } from '../../../models/signs/MessagingResponse.model'

export const getSignEditorCredentials = (
  user: UserInformation,
  projectRoster?: RosterElement[],
  sign?: SignResponse,
) =>
  user.type === UserType.INTERNAL && user.permissions.isSignEditor
    ? true
    : user.type === UserType.EXTERNAL &&
      (userIsCreativeAgent(user.company, projectRoster) ||
        sign?.separator === user.company ||
        userIsCategoryManager(user.company, projectRoster) ||
        sign?.non_retail_item_info.product_vendor === user.company ||
        sign?.printing.print_vendor === user.company ||
        sign?.distribution.kitting_vendor === user.company) &&
      user.permissions.isSignEditor

export const getSelectedSignsEditorCredentials = (
  user: UserInformation,
  projectRoster: RosterElement[],
  selectedSigns: SignResponse[] = [],
) =>
  selectedSigns.length > 0 &&
  selectedSigns.some((sign: SignResponse) =>
    user.type === UserType.INTERNAL && user.permissions.isSignEditor
      ? true
      : user.type === UserType.EXTERNAL &&
        (userIsCreativeAgent(user.company, projectRoster) ||
          sign.separator === user.company ||
          userIsCategoryManager(user.company, projectRoster) ||
          sign.non_retail_item_info.product_vendor === user.company ||
          sign.printing.print_vendor === user.company ||
          sign.distribution.kitting_vendor === user.company) &&
        user.permissions.isSignEditor,
  )

export const isMoreThanTwentyEightDaysOut = (project?: ProjectDetail) =>
  moment(project?.set_date).diff(moment(), 'days') > 28

export const checkEditabilityByPhase = (
  sign: SignResponse,
  field: string,
  user: UserInformation,
  beskarFeature: boolean,
  project?: ProjectDetail,
) => {
  const isDistributionField = SIGN_DISTRIBUTION_FIELDS.includes(field)
  const isPricingField = SIGN_PRICING_FIELDS.includes(field)
  const isFinishedDimension =
    field.startsWith('item') || field.startsWith('finished')
  const isFlatDimension =
    field.startsWith('nominal') || field.startsWith('flat')
  const isContractField = CONTRACT_FIELDS.includes(field)
  const isPrintingField = beskarFeature
    ? SIGN_PRINTING_FIELDS.includes(field)
    : SIGN_PRINTING_FIELDS_OLD.includes(field)
  const userCanEdit = getSignEditorCredentials(user, project?.roster, sign)
  const userIsPrintVendor =
    user.type === (UserType.EXTERNAL || UserType.INTERNAL) &&
    sign.printing.print_vendor === user.company
  const userIsSignAdmin =
    user.type === UserType.INTERNAL && user.permissions.isSignAdmin
  const userIsKitAdmin =
    user.type === UserType.INTERNAL && user.permissions.isKitAdmin
  const userIsKitEditor =
    user.type === UserType.INTERNAL && user.permissions.isKitEditor

  if (PRE_SIGN_FINALIZATION.includes(sign.workflow.status)) {
    if (beskarFeature) {
      return (
        (userCanEdit && !isDistributionField && !isContractField) ||
        (isDistributionField &&
          userIsKitEditor &&
          isMoreThanTwentyEightDaysOut(project)) ||
        (isDistributionField && userIsKitAdmin) ||
        (isContractField && sign.sign_size === 'CUSTOM')
      )
    } else {
      return (
        (userCanEdit &&
          !isDistributionField &&
          !isFinishedDimension &&
          !isFlatDimension) ||
        (isDistributionField &&
          userIsKitEditor &&
          isMoreThanTwentyEightDaysOut(project)) ||
        (isDistributionField && userIsKitAdmin) ||
        (isFinishedDimension &&
          sign.sign_template_info.template_name === 'CUSTOM') ||
        (isFlatDimension &&
          (sign.sign_template_info.size_editable ||
            sign.sign_template_info.template_name === 'CUSTOM'))
      )
    }
  } else if (POST_SIGN_FINALIZATION.includes(sign.workflow.status)) {
    if (field === 'planned_sides') {
      return user.permissions.isSuperAdmin || user.permissions.isSignAdmin
    }
    return (
      (userCanEdit && !isDistributionField) ||
      (userIsSignAdmin && !isDistributionField) ||
      ((isPricingField ||
        field === 'spt_sign_on_pog' ||
        field === 'file_approved' ||
        field === 'end_date') &&
        userCanEdit) ||
      ((isDistributionField || field === 'on_pog_sign') &&
        (userIsKitEditor || userIsKitAdmin))
    )
  } else if (POST_KIT_FINALIZATION.includes(sign.workflow.status)) {
    if (field === 'planned_sides') {
      return user.permissions.isSuperAdmin || user.permissions.isSignAdmin
    }
    return (
      userIsKitAdmin ||
      ((isPricingField || field === 'file_approved') && userCanEdit) ||
      ((isDistributionField || field === 'on_pog_sign') &&
        (userIsKitEditor || userIsKitAdmin)) ||
      (isPrintingField && userIsPrintVendor)
    )
  } else {
    return false
  }
}

export const getProjectStatus = (project: ProjectDetail) =>
  project.workflow.status === 'Kits Created' &&
  project.signs_workflow_status.status === 'Kits Finalized'
    ? 'Finalized Kits - Recreated'
    : project.workflow.status
    ? project.workflow.status
    : 'None'

export const disableSignAction = (
  action: string,
  project: ProjectDetail,
  signs: SignResponse[],
  user: UserInformation,
  requiredFieldChanged: boolean = false,
) => {
  if (signs.length > 0) {
    const userCanEditSigns = getSelectedSignsEditorCredentials(
      user,
      project.roster,
      signs,
    )
    const selectedSignsCanBeEdited = (signs: SignResponse[]) => {
      if (signs.length >= 2) {
        return signs.every(
          (sign: SignResponse) => sign.sign_status === signs[0].sign_status,
        )
      }
    }
    const signCanBeFinalized =
      signs.length > 0 &&
      signs[0].ready_to_finalize &&
      !signs[0].finalized &&
      !requiredFieldChanged
    const isEssSign =
      signs.length > 0 &&
      signs[0].workflow.status === 'Sent to TPS' &&
      signs[0].messaging.sign_size !== 'OTHER'
    const selectedAreEssSigns =
      signs.length > 0 &&
      signs.some(
        (sign: SignResponse) =>
          sign.workflow.status === 'Sent to TPS' &&
          sign.messaging.sign_size !== 'OTHER',
      )
    const isRemovedSign =
      signs.length > 0 && signs[0].sign_status === SIGN_STATUS.REMOVE
    const selectedAreRemovedSigns = signs.every(
      (sign: SignResponse) => sign.sign_status === SIGN_STATUS.REMOVE,
    )
    const selectedSignsCanBeMoved =
      signs.length > 0 &&
      signs.every((sign: SignResponse) => sign.sign_status === 'New')

    if (PROJECT_WORKFLOW_LOCKED.includes(project.workflow.status)) {
      return true
    }

    switch (action) {
      case 'import':
      case 'addNew':
      case 'duplicate':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !userCanEditSigns
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isSignAdmin
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitAdmin
          : true
      case 'bulkEditSelected':
        return !userCanEditSigns || !selectedSignsCanBeEdited(signs)
      case 'moveSelectedSigns':
        return PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitAdmin || !selectedSignsCanBeMoved
          : (!user.permissions.isSignAdmin && !user.permissions.isKitAdmin) ||
              !selectedSignsCanBeMoved
      case 'finalizeSign':
        return PRE_SIGN_FINALIZATION.includes(signs[0].workflow.status) ||
          POST_SIGN_FINALIZATION.includes(signs[0].workflow.status)
          ? !user.permissions.isSignAdmin || !signCanBeFinalized
          : POST_KIT_FINALIZATION.includes(signs[0].workflow.status)
          ? !user.permissions.isKitAdmin || !signCanBeFinalized
          : true
      case 'finalizeAllSigns':
        return (
          !signs.some(
            (sign) =>
              sign.workflow.status ===
              SIGN_WORKFLOW_STATUS.READY_TO_FINALIZE.toString(),
          ) &&
          signs.every((sign) =>
            ALL_SIGNS_CAN_BE_FINALIZED.includes(sign.workflow.status),
          )
        )

      case 'deleteSign':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !userCanEditSigns
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isSignAdmin || !user.permissions.isKitAdmin
          : PROJECT_KITS_CREATED.includes(project.workflow.status)
          ? !user.permissions.isSignAdmin || !user.permissions.isSignAdmin
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitAdmin
          : true
      case 'deleteSelectedSigns':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? signs.length <= 0 || !userCanEditSigns
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? signs.length <= 0 || !user.permissions.isSignAdmin
          : PROJECT_KITS_CREATED.includes(project.workflow.status)
          ? signs.length <= 0 || !user.permissions.isKitAdmin
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? signs.length <= 0 ||
            selectedAreRemovedSigns ||
            selectedAreEssSigns ||
            !user.permissions.isKitAdmin
          : true
      case 'unRemoveSign':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !userCanEditSigns || isEssSign || !isRemovedSign
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isSignAdmin || isEssSign || !isRemovedSign
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitAdmin || isEssSign || !isRemovedSign
          : true
      case 'unRemoveSelectedSigns':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? signs.length <= 0 ||
              !selectedAreRemovedSigns ||
              selectedAreEssSigns ||
              !userCanEditSigns
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? signs.length <= 0 ||
            !selectedAreRemovedSigns ||
            selectedAreEssSigns ||
            !user.permissions.isSignAdmin
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? signs.length <= 0 ||
            !selectedAreRemovedSigns ||
            selectedAreEssSigns ||
            !user.permissions.isKitAdmin
          : true

      case 'deleteSigns':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !userCanEditSigns
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isSignAdmin
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitAdmin
          : true

      case 'groupSigns':
        return signs.length <= 0
      case 'kitScenario':
        return PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitEditor
          : true
      default:
        return false
    }
  } else {
    const userCanEdit = getSignEditorCredentials(user, project.roster)
    switch (action) {
      case 'import':
      case 'addNew':
      case 'duplicate':
        return PROJECT_PRE_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !userCanEdit
          : PROJECT_POST_SIGNS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isSignAdmin
          : PROJECT_POST_KITS_FINALIZED.includes(project.workflow.status)
          ? !user.permissions.isKitAdmin
          : true
      default:
        return true
    }
  }
}

export const fieldIsEditable = (
  sign: SignResponse,
  field: string,
  user: UserInformation,
  beskarFeature: any,
  project?: ProjectDetail,
) => {
  if (field.includes('.')) {
    field = field.split('.')[1]
  }
  const isDistributionField = SIGN_DISTRIBUTION_FIELDS.includes(field)
  const isUnitPricingField = SIGN_UNIT_PRICE_FIELDS.includes(field)
  const isPricingField = SIGN_PRICING_FIELDS.includes(field)
  const isPogField = POG_FIELDS.includes(field)
  const isContractField = CONTRACT_FIELDS.includes(field)
  const isFinishedDimension =
    field.startsWith('item') || field.startsWith('finished')
  const isFlatDimension =
    field.startsWith('nominal') || field.startsWith('flat')
  const userIsPrintVendor =
    user.permissions.isPrintVendor || user.permissions.isTPSVendor
  const canEditByPhase = checkEditabilityByPhase(
    sign,
    field,
    user,
    beskarFeature,
    project,
  )

  if (
    project === undefined ||
    project.workflow.status === 'Archive' ||
    project.workflow.status === 'Cancel'
  ) {
    return false
  }

  if (
    field === 'back_stock_quantity' &&
    (sign.sign_type === 'Value Sign' || sign.sign_type === 'Display Sign') &&
    sign.messaging.sign_size !== 'OTHER' &&
    sign.messaging.sign_size !== ''
  ) {
    return false
  }

  if (
    field === 'third_party_shipping' &&
    PROJECT_KITS_CREATED.includes(project.workflow.status)
  ) {
    return false
  }

  if (
    field === 'mpp' &&
    (sign.messaging.sign_size === '' ||
      !SIGN_SIZE_FACETS[sign.messaging.sign_size].allowMpp)
  ) {
    return false
  }

  if (
    field === 'price' &&
    ((sign.messaging.item_info[0].messaging_type === 'Regular Price' &&
      !sign.messaging.manual_price_override) ||
      sign.messaging.item_info[0].messaging_type === 'Price Range')
  ) {
    return false
  }

  if (
    (field === 'lowest_price' || field === 'highest_price') &&
    sign.messaging.item_info[0].messaging_type !== 'Price Range'
  ) {
    return false
  }

  if (
    field === 'topper_brand' &&
    sign.messaging.sign_size !== SIGN_SIZES.ELEVEN_BY_ELEVEN
  ) {
    return false
  }

  if (field === 'logo_url' && sign.messaging.sign_size === 'OTHER') return false

  if (
    (field === 'inclusions' || field === 'exclusions') &&
    sign.sign_type !== 'Value Sign'
  ) {
    return false
  }
  if (
    field === 'sign_definition' &&
    (sign.sign_size === '' ||
      sign.sign_size === null ||
      sign.sign_size === 'CUSTOM')
  ) {
    return false
  }

  if (field === 'restriction_description') {
    return sign.non_retail_item_info.restricted
  }
  if (
    (field === 'template_id' || field === 'template_name') &&
    sign.sign_template_info.template_name === 'NA' &&
    sign.sign_status !== SIGN_STATUS.NEW
  ) {
    return false
  }

  if (
    sign.sign_size !== 'CUSTOM' &&
    (isContractField || isFinishedDimension || isFlatDimension)
  ) {
    return false
  }

  if (sign.sign_size === 'CUSTOM') {
    if (field === 'po_number' || isUnitPricingField) {
      return !sign.vendor_produced
    }
  } else {
    if (!sign.printing?.is_vendor_has_contract) {
      if (field === 'po_number' || isUnitPricingField) {
        return !sign.vendor_produced
      }
    } else {
      if (
        (field === 'po_number' || isUnitPricingField) &&
        sign.pricing?.po_number === 'N/A'
      ) {
        return false
      } else {
        if (isUnitPricingField) {
          return false /* make it user.permissions.isSuperAdmin when BE accepts over riding prices from BESKAR */
        }
        if (field === 'po_number') {
          return true
        }
      }
    }
  }

  if (sign.sign_status === SIGN_STATUS.REMOVE) {
    switch (field) {
      case 'spt_sign_on_pog':
        return sign.distribution.locations_count > 0 && sign.on_pog_sign
          ? canEditByPhase
          : false
      case 'on_pog_sign':
      case 'end_date':
      case 'sign_status':
        return canEditByPhase
      default:
        return false
    }
  }

  if (sign.sign_status === SIGN_STATUS.CARRY_FORWARD) {
    if (field === 'spt_sign_on_pog') {
      return sign.distribution.locations_count > 0 && sign.on_pog_sign
        ? canEditByPhase
        : false
    } else if (
      field === 'sign_status' ||
      field === 'on_pog_sign' ||
      isPogField
    ) {
      return canEditByPhase
    } else {
      return false
    }
  }

  if (sign.sign_status === SIGN_STATUS.REISSUE) {
    if (field === 'spt_sign_on_pog') {
      return sign.distribution.locations_count > 0 && sign.on_pog_sign
        ? canEditByPhase
        : false
    } else if (field === 'print_vendor') {
      return userIsPrintVendor ? false : canEditByPhase
    } else if (
      isDistributionField ||
      isPricingField ||
      isPogField ||
      field === 'on_pog_sign' ||
      field === 'sign_status'
    ) {
      return canEditByPhase
    } else {
      return false
    }
  }

  if (sign.sign_status === SIGN_STATUS.NEW) {
    switch (field) {
      case 'separator':
        return sign.separator === user.company ? false : canEditByPhase
      case 'product_vendor':
        return user.permissions.isProductVendorOrCatMan ? false : canEditByPhase
      case 'kitting_vendor':
        return user.permissions.isKitVendor ? false : canEditByPhase
      case 'print_vendor':
        return user.permissions.isPrintVendor || user.permissions.isTPSVendor
          ? false
          : canEditByPhase
      case 'spt_sign_on_pog':
        return sign.distribution.locations_count > 0 && sign.on_pog_sign
          ? canEditByPhase
          : false
      case 'set_quantity':
        return sign.unit_of_measure === 'ST' ? canEditByPhase : false
      case 'end_date':
      case 'sign_status':
        return false
      default:
        return canEditByPhase
    }
  }

  return false
}

export const isEditReasonRequired = (project: ProjectDetail) =>
  REASON_CODE_REQUIRED_STATUSES.some(
    (status) => status === project.workflow.status,
  )

export const isSaveButtonShown = (
  sign: SignResponse,
  isInProject: boolean = false,
) => (isInProject ? true : sign.sign_status === SIGN_STATUS.REMOVE)

export const isEditable = (
  sign: SignResponse,
  field: string,
  userPermissions: UserPermissions,
  userCompany: string,
  userType: UserType,
  isInProject: boolean,
  projectStatus?: string,
  projectRoster?: RosterElement[],
  project?: ProjectDetail,
) => {
  //handle fields from table editor
  if (field.includes('.')) {
    field = field.split('.')[1]
  }

  const userCanEdit =
    userType === UserType.INTERNAL && userPermissions.isSignEditor
      ? true
      : userType === UserType.EXTERNAL &&
        (sign.separator === userCompany ||
          sign.non_retail_item_info.product_vendor === userCompany ||
          sign.printing.print_vendor === userCompany ||
          sign.distribution.kitting_vendor === userCompany ||
          isCategoryManager(projectRoster, userCompany) ||
          isCreativeAgent(projectRoster, userCompany)) &&
        userPermissions.isSignEditor

  const distributionFields = [
    'kitting_vendor',
    'kit_by_itself',
    'kit_groups',
    'back_stock_quantity',
    'max_quantity_per_kit',
    'location_filter_info',
    'msc_selection',
    'exclude_msc',
    'include_msc',
    'select_stores',
    'quantity_per_store',
    'selected_msc',
    'include_new_stores',
  ]

  const printingFields = [
    'planned_sides',
    'number_of_colors',
    'substrate1',
    'substrate2',
    'finishing',
    'coating',
    'sides_print',
    'pre_assembled',
    'file_approved',
    'dieline_name',
  ]

  const priceFields = [
    'estimated_unit_price',
    'estimated_quantity',
    'final_unit_price',
    'po_number',
  ]

  const isDistributionFields = distributionFields.includes(field)
  const isPrintingFields = printingFields.includes(field)

  // const userIsSignAdmin =
  //   userType === UserType.INTERNAL && userPermissions.isSignAdmin
  const userIsKitEditor =
    userType === UserType.INTERNAL && userPermissions.isKitEditor
  const userIsKitAdmin =
    userType === UserType.INTERNAL && userPermissions.isKitAdmin
  const userIsPrintVendor =
    userType === (UserType.EXTERNAL || UserType.INTERNAL) &&
    sign.printing.print_vendor === userCompany

  const canEditByPhase = PRE_SIGN_FINALIZATION.includes(sign.workflow.status)
    ? (userCanEdit && !isDistributionFields) ||
      (isDistributionFields && (userIsKitEditor || userIsKitAdmin))
    : POST_SIGN_FINALIZATION.includes(sign.workflow.status)
    ? (userCanEdit && !isDistributionFields) ||
      (userCanEdit &&
        (field === 'spt_sign_on_pog' ||
          field === 'on_pog_sign' ||
          priceFields.includes(field) ||
          field === 'file_approved')) ||
      (isDistributionFields && (userIsKitEditor || userIsKitAdmin))
    : POST_KIT_FINALIZATION.includes(sign.workflow.status)
    ? userIsKitAdmin ||
      (userCanEdit &&
        (priceFields.includes(field) || field === 'file_approved')) ||
      (isDistributionFields && (userIsKitEditor || userIsKitAdmin)) ||
      (isPrintingFields && userIsPrintVendor)
    : false

  if (
    !isInProject ||
    projectStatus === 'Archive' ||
    projectStatus === 'Cancel'
  ) {
    return false
  }

  if (
    field === 'back_stock_quantity' &&
    (sign.sign_type === 'Value Sign' || sign.sign_type === 'Display Sign') &&
    sign.messaging.sign_size !== '' &&
    sign.messaging.sign_size !== 'OTHER'
  ) {
    return false
  }

  if (sign.sign_status === SIGN_STATUS.REMOVE) {
    if (field === 'spt_sign_on_pog') {
      return sign.distribution.locations_count > 0 && sign.on_pog_sign
        ? canEditByPhase
        : false
    } else if (field === 'on_pog_sign') {
      return canEditByPhase
    } else if (field === 'end_date') {
      return canEditByPhase
    } else {
      return false
    }
  }

  if (sign.sign_status === SIGN_STATUS.CARRY_FORWARD) {
    if (field === 'spt_sign_on_pog') {
      return sign.distribution.locations_count > 0 && sign.on_pog_sign
        ? canEditByPhase
        : false
    } else if (field === 'sign_status' || field === 'on_pog_sign') {
      return canEditByPhase
    } else {
      return false
    }
  }

  if (sign.sign_status === SIGN_STATUS.REISSUE) {
    if (field === 'spt_sign_on_pog') {
      return sign.distribution.locations_count > 0 && sign.on_pog_sign
        ? canEditByPhase
        : false
    } else if (field === 'print_vendor') {
      return userPermissions.isPrintVendor || userPermissions.isTPSVendor
        ? false
        : canEditByPhase
    } else if (
      distributionFields.includes(field) ||
      field === 'back_stock_quantity' ||
      priceFields.includes(field) ||
      field === 'on_pog_sign' ||
      field === 'sign_status'
    ) {
      return canEditByPhase
    } else {
      return false
    }
  }

  if (sign.sign_status === SIGN_STATUS.NEW) {
    if (field === 'spt_sign_on_pog') {
      return sign.distribution.locations_count > 0 && sign.on_pog_sign
        ? canEditByPhase
        : false
    } else if (field === 'separator') {
      return sign.separator === userCompany ? false : canEditByPhase
    } else if (field === 'product_vendor') {
      return userPermissions.isProductVendorOrCatMan ? false : canEditByPhase
    } else if (field === 'set_quantity') {
      return sign.unit_of_measure !== 'ST' ? false : canEditByPhase
    } else if (field === 'kitting_vendor' || field === 'kit_groups') {
      return userPermissions.isKitVendor ? false : canEditByPhase
    } else if (field === 'print_vendor') {
      return userPermissions.isPrintVendor || userPermissions.isTPSVendor
        ? false
        : canEditByPhase
    } else if (field === 'end_date') {
      return false
    } else {
      return canEditByPhase
    }
  }

  return false
}
