import { KitInfoResponse } from '../../../../../models/kits/KitInfoResponse.model'
import {
  Button,
  Caption,
  Chip,
  GridContainer,
  GridItem,
  Popover,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import EnterpriseIcon, { InfoIcon, PencilIcon } from '@enterprise-ui/icons'
import React, { useState, Fragment } from 'react'
import ProjectKitInfoForm from './ProjectKitInfoForm'
import { useUserContext } from 'components/App/context/userContext'
import RubixChip from 'components/common/RubixChip'

export interface Props {
  kittingInfo: KitInfoResponse
}

const ProjectKitInfoView = ({ kittingInfo }: Props) => {
  const [isKitInfoFormOpen, setIsKitInfoFormOpen] = useState(false)
  const { userPermissions } = useUserContext()!
  return (
    <Fragment>
      <GridContainer
        justify="space-between"
        align="center"
        className=" hc-pb-none hc-pt-none"
      >
        <GridItem>
          <Caption uppercase above="In-Store Date" className="hc-pt-normal">
            <Chip size="dense">
              {kittingInfo.initial_receive_dates?.instore_date &&
              moment(kittingInfo.initial_receive_dates?.instore_date).isValid()
                ? moment(
                    kittingInfo.initial_receive_dates?.instore_date,
                  ).format('MM/DD/YYYY')
                : ''}
            </Chip>
          </Caption>
        </GridItem>
        <GridItem>
          <Caption uppercase above="NRSC Receive Date" className="hc-pt-normal">
            <Chip size="dense">
              {kittingInfo.initial_receive_dates?.nrsc_receive_date &&
              moment(
                kittingInfo.initial_receive_dates?.nrsc_receive_date,
              ).isValid()
                ? moment(
                    kittingInfo.initial_receive_dates?.nrsc_receive_date,
                  ).format('MM/DD/YYYY')
                : ''}
            </Chip>
          </Caption>
        </GridItem>
        <GridItem xs={2}>
          <Caption
            uppercase
            above="BackStock Receive Date"
            className="hc-pt-normal"
          >
            <Chip size="dense">
              {kittingInfo.initial_receive_dates?.back_stock_receive_date &&
              moment(
                kittingInfo.initial_receive_dates?.back_stock_receive_date,
              ).isValid()
                ? moment(
                    kittingInfo.initial_receive_dates?.back_stock_receive_date,
                  ).format('MM/DD/YYYY')
                : ''}
            </Chip>
          </Caption>
        </GridItem>
        <GridItem xs={2}>
          <Caption uppercase above="Shipping Method" className="hc-pt-normal">
            <Chip size="dense">{kittingInfo.shipping_method}</Chip>
          </Caption>
        </GridItem>
        <GridItem xs={2}>
          <Caption
            uppercase
            above={
              <Fragment>
                <span>Project Comments</span>
                <Popover
                  className="sem_KitCommentsPopover"
                  size="expanded"
                  content={<p>{kittingInfo.comments?.kit_comment || '-'}</p>}
                  location="bottom"
                >
                  <Button
                    aria-label="View Info"
                    type="ghost"
                    size="dense"
                    iconOnly
                    className="hc-pt-none"
                  >
                    <EnterpriseIcon
                      size="inline"
                      icon={InfoIcon}
                      color={'grey'}
                    />
                  </Button>
                </Popover>
              </Fragment>
            }
          >
            <p className="sem_KitComments">
              {kittingInfo.comments?.kit_comment || '-'}
            </p>
          </Caption>
        </GridItem>
        <GridItem className="hc-pt-normal">
          <Caption uppercase above="Kits Finalized?" className="hc-pt-normal">
            {kittingInfo.kits_finalized && (
              <RubixChip color="green">YES</RubixChip>
            )}
            {!kittingInfo.kits_finalized && <Chip size="dense">NO</Chip>}
          </Caption>
        </GridItem>
        {userPermissions.isKitEditor && (
          <GridItem className="hc-pt-normal">
            <Tooltip location="bottom-right" content="Edit Dates & Comments">
              <Button
                aria-label="Edit Dates & Comments"
                type="ghost"
                iconOnly
                onClick={() => {
                  setIsKitInfoFormOpen(true)
                }}
              >
                <EnterpriseIcon size="sm" icon={PencilIcon} />
              </Button>
            </Tooltip>
          </GridItem>
        )}
      </GridContainer>
      <ProjectKitInfoForm
        kittingInfo={kittingInfo}
        isKitInfoFormOpen={isKitInfoFormOpen}
        setKitInfoFormOpen={setIsKitInfoFormOpen}
      />
    </Fragment>
  )
}

export default ProjectKitInfoView
