import React, { Fragment, useState } from 'react'
import {
  Grid,
  ExpandableSection,
  Heading,
  Divider,
  Button,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { SIGN_DETAILS_SECTION } from '../constants/signDetailsConstants'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import { getVendorOptions } from '../../App/helpers/vendorHelpers'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { useSignBulkEditContext } from '../../SignBulkEdit/context/signBulkEditContext'
import SignBulkCheckbox from '../../SignBulkEdit/components/SignBulkCheckbox'
import { useUserContext } from '../../App/context/userContext'
import { useSignFormsContext } from '../context/signFormsContext'
import { useAppContext } from '../../App/context/appContext'
import SignResponse from '../../../models/signs/SignResponse.model'
import { fieldIsEditable } from '../../App/helpers/signEditHelper'
import { useEnv } from '@praxis/component-runtime-env'

export interface Props {
  isBulkEdit?: boolean
  selectedSigns?: SignResponse[]
}

const SignPrintSection = ({ isBulkEdit, selectedSigns }: Props) => {
  const env = useEnv()
  const { setPageHasChanges } = useAppContext()!
  const {
    isInProject,
    currentSign,
    expandedSection,
    signFacets,
    setExpandedSection,
    modifiedSections,
    addToModifiedSections,
    setRequiredFieldsChanged,
    editReasonRequired,
    setActionType,
    setShowReasonCodeModal,
    project,
  } = useSignDetailsContext()!
  const { addBulkEditField, selectedFields } = useSignBulkEditContext()!
  const { signPrintFormik } = useSignFormsContext()!
  const { userInfo, userPermissions, userCompany } = useUserContext()!
  const binarySelect = [
    new DropdownOption({ value: false, label: 'No' }),
    new DropdownOption({ value: true, label: 'Yes' }),
  ]
  const sidesPrint = [
    new DropdownOption({ value: false, label: 'Uncommon' }),
    new DropdownOption({ value: true, label: 'Common' }),
  ]
  const sidesSelect = [
    new DropdownOption({ value: 1, label: '1' }),
    new DropdownOption({ value: 2, label: '2' }),
  ]
  const colorNumsSelect = [
    new DropdownOption({ value: 1, label: '1' }),
    new DropdownOption({ value: 2, label: '2' }),
    new DropdownOption({ value: 3, label: '3' }),
    new DropdownOption({ value: 4, label: '4' }),
  ]

  const substrateOptions = signFacets.sign_substrate
  const [substrate1Selection, setSubstrate1Selection] = useState<
    string | undefined
  >(undefined)
  const [substrate2Selection, setSubstrate2Selection] = useState<
    string | undefined
  >(undefined)

  const availableSubstrateOptions = (id: string) => {
    if (id === 'substrate1') {
      return substrate2Selection
        ? substrateOptions.filter(
            (substrate) => !substrate2Selection.includes(substrate.label),
          )
        : substrateOptions
    } else if (id === 'substrate2') {
      return substrate1Selection
        ? substrateOptions.filter(
            (substrate) => !substrate1Selection.includes(substrate.label),
          )
        : substrateOptions
    }
  }

  const isDisabled = (field: string) => {
    return !fieldIsEditable(
      currentSign,
      field,
      userInfo,
      env.beskarFeature,
      project,
    )
  }

  const handleChange = (
    id: string,
    value: string | number,
    optionalId?: string,
  ) => {
    if (isBulkEdit) {
      if (optionalId !== undefined) {
        addBulkEditField('printing', {
          print_vendor: value,
          print_vendor_id: optionalId,
        })
      } else {
        addBulkEditField('printing.' + id, value)
      }
    }
    if (id === 'substrate1') {
      setSubstrate1Selection(value as string)
    }
    if (id === 'substrate2') {
      setSubstrate2Selection(value as string)
    }
    signPrintFormik.setFieldValue(id, value)
    addToModifiedSections(SIGN_DETAILS_SECTION.PRINT)
    setPageHasChanges(true)
  }
  return (
    <Fragment>
      <Grid.Item className="hc-pv-none hc-ph-normal" xs={12}>
        <ExpandableSection
          padding="none"
          className={`sem_BlueprintFormCard ${
            modifiedSections.includes(SIGN_DETAILS_SECTION.PRINT)
              ? 'modified'
              : ''
          }`}
          expanded={expandedSection === SIGN_DETAILS_SECTION.PRINT}
          onExpand={() =>
            setExpandedSection(
              expandedSection === SIGN_DETAILS_SECTION.PRINT
                ? ''
                : SIGN_DETAILS_SECTION.PRINT,
            )
          }
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey01 hc-pl-normal" size={5}>
                Printing Information
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container
                align="center"
                className={`sem_StandardForm ${
                  isInProject ? 'hc-mb-normal error_messaging_enabled' : ''
                }`}
              >
                <Grid.Item xs={6}>
                  <Autocomplete
                    id="print_vendor"
                    label="Print Vendor"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.print_vendor"
                          rightContent={true}
                          disabled={isDisabled('print_vendor')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.print_vendor')
                        : isDisabled('print_vendor')
                    }
                    options={(query: string) =>
                      getVendorOptions(
                        query,
                        signPrintFormik.values.print_vendor!,
                      )
                    }
                    value={{
                      value: signPrintFormik.values.print_vendor,
                      label: signPrintFormik.values.print_vendor,
                    }}
                    hintText={
                      !signPrintFormik.values.print_vendor && !isBulkEdit
                        ? 'Select a print vendor for sign finalization.'
                        : ''
                    }
                    highlight={
                      !signPrintFormik.values.print_vendor && !isBulkEdit
                    }
                    onUpdate={(id: string, value: any) => {
                      if (
                        value &&
                        value.value !== signPrintFormik.values.print_vendor
                      ) {
                        signPrintFormik.setFieldValue(
                          'print_vendor_id',
                          value.id,
                        )
                        handleChange(id, value.value, value.id)
                        setRequiredFieldsChanged(true)
                      }
                    }}
                    required
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="planned_sides"
                    label="Planned Sides"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.planned_sides"
                          rightContent={true}
                          disabled={isDisabled('planned_sides')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.planned_sides')
                        : isDisabled('planned_sides')
                    }
                    value={signPrintFormik.values.planned_sides}
                    hintText={
                      !signPrintFormik.values.planned_sides && !isBulkEdit
                        ? 'Select planned sides for sign finalization.'
                        : ''
                    }
                    highlight={
                      !signPrintFormik.values.planned_sides && !isBulkEdit
                    }
                    onUpdate={(id: string, value: number) => {
                      handleChange(id, value)
                      setRequiredFieldsChanged(true)
                    }}
                    options={sidesSelect}
                    required
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="number_of_colors"
                    label="Number of Colors"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.number_of_colors"
                          rightContent={true}
                          disabled={isDisabled('number_of_colors')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.number_of_colors')
                        : isDisabled('number_of_colors')
                    }
                    value={signPrintFormik.values.number_of_colors}
                    onUpdate={(id: string, value: number) => {
                      handleChange(id, value)
                    }}
                    options={colorNumsSelect}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Autocomplete
                    id="substrate1"
                    label="Substrate 1"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.substrate1"
                          rightContent={true}
                          disabled={isDisabled('substrate1')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.substrate1')
                        : isDisabled('substrate1')
                    }
                    value={{
                      value: signPrintFormik.values.substrate1
                        ? signPrintFormik.values.substrate1
                        : '',
                      label: signPrintFormik.values.substrate1
                        ? signPrintFormik.values.substrate1
                        : '',
                    }}
                    options={() => availableSubstrateOptions('substrate1')}
                    onUpdate={(id: string, value: any) => {
                      if (value) {
                        handleChange(id, value.value)
                      } else {
                        handleChange(id, '')
                      }
                    }}
                    allowCustomInputValue
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Autocomplete
                    id="substrate2"
                    options={() => availableSubstrateOptions('substrate2')}
                    label="Substrate 2"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.substrate2"
                          rightContent={true}
                          disabled={isDisabled('substrate2')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.substrate2')
                        : isDisabled('substrate2')
                    }
                    value={{
                      value: signPrintFormik.values.substrate2
                        ? signPrintFormik.values.substrate2
                        : '',
                      label: signPrintFormik.values.substrate2
                        ? signPrintFormik.values.substrate2
                        : '',
                    }}
                    onUpdate={(id: string, value: any) => {
                      if (value) {
                        handleChange(id, value.value)
                      } else {
                        handleChange(id, '')
                      }
                    }}
                    allowCustomInputValue
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="finishing"
                    label="Finishing"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.finishing"
                          rightContent={true}
                          disabled={isDisabled('finishing')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.finishing')
                        : isDisabled('finishing')
                    }
                    value={signPrintFormik.values.finishing}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="coating"
                    label="Coating"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.coating"
                          rightContent={true}
                          disabled={isDisabled('coating')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.coating')
                        : isDisabled('coating')
                    }
                    value={signPrintFormik.values.coating}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="dieline_name"
                    label="Dieline Name"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.dieline_name"
                          rightContent={true}
                          disabled={isDisabled('dieline_name')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.dieline_name')
                        : isDisabled('dieline_name')
                    }
                    value={signPrintFormik.values.dieline_name}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.value)
                    }}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="sides_print"
                    label="Sides Print"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.sides_print"
                          rightContent={true}
                          disabled={isDisabled('sides_print')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.sides_print')
                        : isDisabled('sides_print')
                    }
                    value={signPrintFormik.values.sides_print}
                    onUpdate={(id: string, value: number) => {
                      handleChange(id, value)
                    }}
                    options={sidesPrint}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="pre_assembled"
                    label="Pre-Assembled"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="printing.pre_assembled"
                          rightContent={true}
                          disabled={isDisabled('pre_assembled')}
                        />
                      )
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.pre_assembled')
                        : isDisabled('pre_assembled')
                    }
                    value={
                      signPrintFormik.values.planned_sides === undefined &&
                      currentSign.unit_of_measure === 'KT'
                        ? true
                        : signPrintFormik.values.pre_assembled
                    }
                    onUpdate={(id: string, value: number) => {
                      handleChange(id, value)
                    }}
                    options={binarySelect}
                  />
                </Grid.Item>
                <Grid.Item className="hc-mv-normal">
                  {isBulkEdit && (
                    <SignBulkCheckbox
                      fieldType="printing.file_approved"
                      disabled={isDisabled('file_approved')}
                    />
                  )}
                  <Form.Field
                    className="hc-clr-grey02 sem_StrongText"
                    type="checkbox"
                    id="file_approved"
                    label="File Approved"
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('printing.file_approved')
                        : isDisabled('file_approved')
                    }
                    checked={signPrintFormik.values.file_approved}
                    onChange={(e: any) => {
                      handleChange(e.target.id, e.target.checked)
                    }}
                  />
                </Grid.Item>
                {isInProject && !isBulkEdit && (
                  <Divider className="hc-pa-dense" />
                )}
              </Grid.Container>
              {isInProject &&
                !isBulkEdit &&
                (userPermissions.isSignEditor ||
                  currentSign.separator === userCompany) && (
                  <Grid.Container
                    className="hc-mt-normal"
                    direction="row-reverse"
                  >
                    <Grid.Item className="hc-pb-dense hc-pt-normal">
                      <Button
                        fullWidth
                        type="primary"
                        size="dense"
                        disabled={
                          !modifiedSections.includes(SIGN_DETAILS_SECTION.PRINT)
                        }
                        onClick={() => {
                          if (editReasonRequired) {
                            setActionType('Print Edit')
                            setShowReasonCodeModal(true)
                          } else {
                            signPrintFormik.handleSubmit()
                          }
                        }}
                      >
                        Save Changes
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
      <Grid.Item className="hc-mv-dense" xs={12}>
        <Divider />
      </Grid.Item>
    </Fragment>
  )
}

export default SignPrintSection
