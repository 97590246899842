import { get } from 'lodash'

export default class DistributionResponse {
  back_stock_quantity?: number | null
  include_msc_pog_locations?: boolean
  kit_by_itself: boolean
  kit_group_quantities?: KitGroupQuantity[]
  kit_groups: string[]
  kitting_vendor: string
  kitting_vendor_id: string
  locations_count: number
  location_filter_info: LocationFilterCriteria
  max_quantity_per_kit?: number
  locations: LocationQuantity[]
  nrsc_quantity?: number | null
  quantity_per_store?: number
  total_quantity?: number | null
  location_update_status?: LocationUpdateStatus
  include_new_stores: boolean
  constructor(props: any = {}) {
    this.back_stock_quantity =
      props.back_stock_quantity !== undefined
        ? parseInt(props.back_stock_quantity)
        : undefined
    this.include_msc_pog_locations = props.include_msc_pog_locations || false
    this.kit_by_itself = props.kit_by_itself || false
    this.kit_group_quantities = get(props, 'kit_group_quantities', []).map(
      (kitGroup: any) => new KitGroupQuantity(kitGroup),
    )
    this.kit_groups = props.kit_groups || []
    this.kitting_vendor = props.kitting_vendor || ''
    this.kitting_vendor_id = props.kitting_vendor_id || ''
    this.locations_count = props.locations_count || 0
    this.location_filter_info = new LocationFilterCriteria(
      props.location_filter_info || {},
    )
    this.max_quantity_per_kit = props.max_quantity_per_kit
    this.locations = get(props, 'locations', [{}]).map(
      (mscLocation: any) => new LocationQuantity(mscLocation),
    )
    this.nrsc_quantity =
      props.nrsc_quantity !== undefined
        ? parseInt(props.nrsc_quantity)
        : undefined
    this.quantity_per_store = props.quantity_per_store
    this.total_quantity =
      props.total_quantity !== undefined
        ? parseInt(props.total_quantity)
        : props.back_stock_quantity === undefined &&
          props.nrsc_quantity === undefined
        ? null
        : parseInt(props.back_stock_quantity ? props.back_stock_quantity : 0) +
          parseInt(props.nrsc_quantity ? props.nrsc_quantity : 0)
    this.location_update_status = props.location_update_status
    this.include_new_stores = props.include_new_stores === true
  }
}

export class LocationUpdateStatus {
  location_updated: boolean
  id: Date

  constructor(props: any = {}) {
    this.location_updated = props.location_updated
    this.id = props.id
  }
}

export class LocationQuantity {
  location_id: number
  quantity: number

  constructor(props: any = {}) {
    this.location_id = props.location_id
    this.quantity = props.quantity
  }
}

export class LocationFilterCriteria {
  exclude_location_info: LocationInfo
  include_location_info: LocationInfo

  constructor(props: any = {}) {
    this.exclude_location_info = new LocationInfo(
      props.exclude_location_info || {},
    )
    this.include_location_info = new LocationInfo(
      props.include_location_info || {},
    )
  }
}

export class LocationInfo {
  all_locations?: boolean
  department_location_groups: DepartmentLocationGroup[]
  locations: string[]

  constructor(props: any = {}) {
    this.all_locations = props.all_locations ? props.all_locations : undefined
    // department_location_groups needs to have at least a blank row to render properly in the Distribution MSC section
    this.department_location_groups =
      props.department_location_groups !== undefined &&
      props.department_location_groups.length > 0
        ? get(props, 'department_location_groups', [{}]).map(
            (item: any) => new DepartmentLocationGroup(item),
          )
        : [new DepartmentLocationGroup()]
    this.locations = props.locations || []
  }
}

export class DepartmentLocationGroup {
  department_id: string
  group_id: string
  sub_group_ids: string[]
  quantity: number

  constructor(props: any = {}) {
    this.department_id = props.department_id || ''
    this.group_id = props.group_id || ''
    this.sub_group_ids = props.sub_group_ids || []
    this.quantity = props.quantity || 0
  }
}

export class KitGroupQuantity {
  group_id: string
  quantity: number

  constructor(props: any = {}) {
    this.group_id = props.group_id || ''
    this.quantity = props.quantity || 0
  }
}
